import Vue from 'vue'
import Router from 'vue-router'
import router_zh from './zh.js'
import router_en from  './en.js'
import store from '../store/index'
const originalPush = Router.prototype.replace

Router.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
const other = [
  {
    path: '/first',
    name:"first",
    component: () => import('@/views/first.vue')
  },
  // 分享 -- 旧链接
  {
    path: '/qorder',
    name:"快速下单",
    component: () => import('@/views/old_share.vue')
  },
  {
    path: '/coupon',
    name:"快速下单",
    component: () => import('@/views/auto.vue')
  },
  {
    path: '/square-event',
    name:"快速下单",
    component: () => import('@/views/activity.vue')
  },
  {
    path: '/mid_autumn',
    name:"中秋",
    component: () => import('@/views/mid_autumn.vue')
  },
   // 404
   {
    path: '/error',
    name:"error",
    meta: { isLocked: true },
    component: () => import('@/views/error.vue')
  },
  // 404
  {
    path: '*',
    name:"404",
    component: () => import('@/views/404.vue')
  },
]
export const routes = router_zh.concat(router_en, other)
 
const createRouter = () => new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
})

const router = createRouter()
// 存储原始history方法
const originalPushState = window.history.pushState
const originalReplaceState = window.history.replaceState

// 增强版历史记录控制
function hijackHistoryMethods() {
  window.history.pushState = function(state, title, url) {
    if (router.currentRoute.meta.isLocked) {
      return originalReplaceState.call(
        window.history,
        state,
        title,
        router.currentRoute.fullPath
      )
    }
    return originalPushState.call(window.history, state, title, url)
  }

  window.history.replaceState = function(state, title, url) {
    if (router.currentRoute.meta.isLocked) {
      return originalReplaceState.call(
        window.history,
        state,
        title,
        router.currentRoute.fullPath
      )
    }
    return originalReplaceState.call(window.history, state, title, url)
  }
}

// 用户状态检查（实际需要对接后端API）
function checkUserStatus() {
  // 返回示例：true表示非法用户
  return sessionStorage.getItem('isIllegalUser') === 'true'
}
//  路由守卫
router.beforeEach(  (to, from, next) => {
  if (checkUserStatus()) {
    if (to.name !== 'error') {
      next('/error')
      hijackHistoryMethods()
      return
    }
  }
  
  if(to.meta.auth) {
    if(localStorage.getItem('token')) {
      next()
    }else {
      let lang = to.path.split('/')
      router.push({ path: `/${lang[1]}/login` } )
    }
  }else {
    next()
  }
  
})
router.beforeEach((to, from, next) => {
  if (!store.state.jsUrl) {
    // 记录第一次进入的URL
    store.commit('set_url', document.URL)
  }
  next();
})

/* eslint-disable */
router.afterEach((to) => {
  if (to.meta.isLocked) {
    window.history.replaceState(null, null, to.fullPath)
    hijackHistoryMethods()
  }
  // 每一次切换 向上层postMsg
  if(store.state.isMiniProgram) {
    wx.miniProgram.postMessage({
      data: {
        path: to.fullPath,
        mate: to.meta
      }
    })
  }
})

// 拦截浏览器导航事件
window.addEventListener('popstate', (event) => {
  if (router.currentRoute.meta.isLocked) {
    router.replace('/error')
    window.history.pushState(null, null, router.currentRoute.fullPath)
  }
})

// 初始化时检查
if (checkUserStatus()) {
  router.replace('/error')
  hijackHistoryMethods()
}


export default router